import React from 'react'
import { graphql } from 'gatsby';
import styled from 'styled-components'
import Container from '../elements/Container'
import Layout from '../components/Layout'
import Header from '../components/Header'

const Base = styled(Container)`
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
`


export default ({ data }) => {
  // Define the Blog Home & Blog Post content returned from Prismic
  const posts = data.prismic.allPosts.edges;

  if(!posts) return null;

  return(
    <Layout>
      <Header title='Tipps' imageUrl='https://images.prismic.io/reiseamseln/25e845ae-476f-4c70-a97f-99deb405763f_cover1.jpg?auto=compress,format'></Header>
      <Base type="big">
        <Container>
          <h3>
            Derzeit in Bearbeitung
          </h3>
        </Container>
      </Base> 
    </Layout>
  )
}

export const query = graphql`
{
  prismic{
    allPosts(sortBy: date_DESC){
      edges{
        node{
          _meta{
            id
            uid
            type
          }
          title
          date
          timespan
          emojis
          titleImage
          intro
        }
      }
    }
  }
}
`
